<script setup lang="ts">
import type { SwiperOptions } from 'swiper/types';
import Swiper from 'swiper';
import { FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';

const {
    slideClass = '',
    startPosition = 'left',
} = defineProps<{
    slideClass?: string;
    startPosition?: 'left' | 'right';
}>();

const elContainer = useTemplateRef('elContainer');

let swiper: Swiper;
onMounted(async () => {
    await new Promise(resolve => setTimeout(resolve, 100));

    if (!elContainer.value) {
        return;
    }
    swiper = new Swiper(elContainer.value, {
        modules: [FreeMode],
        freeMode: {
            enabled: true,
        },
        slidesPerView: 'auto',
        spaceBetween: 0,

        on: {
            resize(instance) {
                if (startPosition === 'right') {
                    instance.setProgress(1);
                }
            },

            afterInit(instance) {
                if (startPosition === 'right') {
                    instance.setProgress(1);
                }
            },

        },
    } satisfies SwiperOptions);
});

async function recalculate() {
    await nextTick();
    if (swiper) {
        swiper.updateSlides();
        if (startPosition === 'right') {
            swiper.setProgress(1);
        }
    }
}

defineExpose({
    recalculate,
});
</script>

<template>
    <div ref="elContainer" class="swiper w-full">
        <div class="swiper-wrapper">
            <div class="swiper-slide !w-auto" :class="slideClass">
                <slot />
            </div>
        </div>
    </div>
</template>
